var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('v-card',[_c('v-container',[_c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{staticStyle:{"margin":"0px","padding":"0px"},attrs:{"flat":"","color":"primary","fixed-tabs":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.tabs),function(item,index){return _c('v-tab',{key:'a' + index},[_c('span',[_vm._v(_vm._s(item)+" ")])])})],2)],1),(_vm.tab == 0)?_c('small',[_vm._v("Odottavilla korotuksilla laskutus ei ole seuraavan toistuvan laskun yhteydessä.")]):_vm._e(),(_vm.tab == 1)?_c('small',[_vm._v("Aktivoiduilla korotuksilla korotettu lasku lähetetään seuraavan laskun yhteydessä ja korotetut tuotteet on siirretty laskulle.")]):_vm._e(),(_vm.tab == 2)?_c('small',[_vm._v("Laskutetuilla korotuksilla ensimmäinen korotettu lasku on jo lähetetty.")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.rentIncreases,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.rentalContract.contractNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (item.rentalContract._id))}},[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(item.rentalContract.contractNumber)+" ")])])]}},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.rentalContract.apartment.id._id))}},[_c('span',[_vm._v(_vm._s(item.rentalContract.apartment.id.address))]),(item.rentalContract.apartment.id.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.rentalContract.apartment.id.apartmentNumber)+" ")]):_vm._e()])]}},{key:"item.tenant",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item.rentalContract.tenant.tenantId._id))}},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.rentalContract.tenant.name)+" ")])])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dueDate)))])]}},{key:"item.baseInvoice",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatBaseInvoice(item.baseInvoice))}})]}},{key:"item.productDrafts",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatProductDrafts(item))}})]}},{key:"item.sendDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.formatSendDate(item))))])]}},{key:"item.dontSendMessage",fn:function(ref){
var item = ref.item;
return [_c('p',[(item.dontSendMessage)?_c('span',[_vm._v(_vm._s(_vm.formatBoolean(!item.dontSendMessage)))]):_vm._e(),(!item.dontSendMessage)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]):_vm._e()]),(
            _vm.formatRentIncreaseMessageStatus(item.rentIncreaseMessageStatus) &&
            !item.dontSendMessage
          )?_c('div',[_c('span',{staticClass:"font-weight-bold",class:_vm.getRentIncreaseMessageColor(item.rentIncreaseMessageStatus)},[_vm._v(_vm._s(_vm.formatRentIncreaseMessageStatus(item.rentIncreaseMessageStatus)))])]):_vm._e()]}},{key:"item.sendType",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getSendIcon(item.sendType)))])],1)]}},{key:"item.products",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.getTotalAmount(item.products))))])]}},{key:"item.activated",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:!item.activated
              ? 'draft--text'
              : item.activated && !item.done
              ? 'warning--text'
              : 'success--text'},[_vm._v(_vm._s(!item.activated ? "Odottaa aktivointia" : item.activated && !item.done ? "Aktivoitu / Ei laskutettu" : "Valmis / Laskutettu"))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('rentIncrease', 'read'))?_c('v-icon',{attrs:{"title":"Lataa pdf","small":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v("mdi-file-pdf")]):_vm._e(),(_vm.isAuthorized('rentIncrease', 'delete') && !item.done)?_c('v-icon',{attrs:{"title":"Poista korotus","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v(" "+_vm._s(_vm.tab == 0 ? "Ei aktivointia odottavia korotuksia" : _vm.tab == 1 ? "Ei aktivoituja korotuksia" : "Ei lähetettyjä korotuksia")+" ")])]},proxy:true}],null,true)})],1),_c('rent-increase-dialog',{model:{value:(_vm.rentIncreaseDialog),callback:function ($$v) {_vm.rentIncreaseDialog=$$v},expression:"rentIncreaseDialog"}}),_c('delete-active-rent-increase',{model:{value:(_vm.deleteRentIncreaseDialog),callback:function ($$v) {_vm.deleteRentIncreaseDialog=$$v},expression:"deleteRentIncreaseDialog"}}),_c('download-loader',{attrs:{"loading":_vm.downloading,"text":"Odota, ladataan vuokrankorotusilmoitusta..."},model:{value:(_vm.downloading),callback:function ($$v) {_vm.downloading=$$v},expression:"downloading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }